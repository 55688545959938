html {
    color:#f8f8f8;
}
html, body {
    background: #2E2E2E;
    font-family: 'roboto-light';
    font-size:26px;
    min-height:100%;
    height:100%;
}
div, header, footer {
    font-family:inherit;
    font-size:inherit;
    color:#f8f8f8;
}

.inline {
    padding-left:1rem;
    padding-right:1rem;
    color:inherit;
}

a {
    text-decoration:none;
    color:inherit;
}

.hover:hover {
    cursor:pointer;
    color:#fff;
}

.text-gold {
    //background: linear-gradient(to bottom, #EDD060 17%, #FAE97E 25%, #F2D260 78%, #AF7820 92%);
    background-image:url('../img/verion/images/bg-text.png');
    background-repeat: repeat;
    background-size:1.5%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    //&::after {
    //    background: none;
    //    content: attr(data-heading) / "";
    //    left: 0;
    //    top: 0;
    //    z-index: -1;
    //    position: absolute;
    //    text-shadow: -1px 0 1px #c6bb9f,
    //    0 1px 1px #c6bb9f,
    //    5px 5px 10px rgba(0, 0, 0, 0.4),
    //    -5px -5px 10px rgba(0, 0, 0, 0.4);
    //}
}
.text-aqua          { color:#5BDBEA !important; }
.text-green         { color:#01A17F !important; }
.text-greylight     { color:#999 !important; }
.text-grey          { color:#444 !important; }
.text-bold          { font-weight:700; }
.text-xbold         { font-weight:900; }
.text-center        { text-align:center; }
.text-xsmall        { font-size:50%; }
.text-smaller       { font-size:70%; }
.text-smallest      { font-size:60%; }
.text-red           { color:#B20000 !important; }
.text-italic        { font-style:italic; }
.text-white         { color:#f8f8f8; }
.text-black         { color:#333 !important; }
.text-justify       { text-align:justify; }
.text-glow          { text-shadow:1px 1px 10px rgba(0,0,0,0.4) , 1px 1px 10px rgba(0,0,0,0.4); }
.font-roboto-bold   { font-family:'roboto-bold' !important; }

.float-none         { float:none; }
.float-left         { float:left; }
.float-right        { float:right; }
.w-auto             { width:auto; }
.w-50               { width:50%; }
.h-100              { height:100%; }

.plr05              { padding-left:0.5em; padding-right:0.5rem;}
.pl05               { padding-left:0.5rem; }
.pl1                { padding-left:1rem; }
.pt02               { padding-top:0.2rem; }
.pt03               { padding-top:0.3rem; }
.pt05               { padding-top:0.5rem; }
.mt-03              { margin-top:-0.3rem !important; }
.p05                { padding:0.5rem !important;}
.pt1                { padding-top:1rem; }
.pt2                { padding-top:2rem; }
.pt3                { padding-top:3rem; }
.pt5                { padding-top:5rem; }
.pt6                { padding-top:6rem; }
.pb01               { padding-bottom:0.1rem; }
.pb08               { padding-bottom:0.8rem !important;}
.pb05               { padding-bottom:0.5rem;}
.pb1                { padding-bottom:1rem;}
.pb3                { padding-bottom:3rem;}
.pb5                { padding-bottom:5rem;}

h1                  { font-family:'roboto-medium'; }

.center-horizontal  { left: 50%; transform: translateX(-50%); width:auto; }


pre.debug {
    z-index:999999;
    border:2px solid orange;
    color:#999;
}
