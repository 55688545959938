.panel {
    border-radius:11px;
    padding:0.3rem 0.8rem;
    border:1.8px solid rgba(1,161,127, 0.4); //#01A17F; //#A4E5EB;
    background:#01A17F;
    //background: linear-gradient(131deg, rgba(224,246,248,1) 0%, rgba(208,241,244,1) 100%);
    background:rgba(1,161,127, 0.1);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    &.panel-asymetric {
        border-top-right-radius:27px;
        margin-bottom:1rem;
    }

    &.panel-check::before {
        content:url('../img/verion/icons/icon-check.png');
        transform: scale(.05);
        position:absolute;
        width:20px;
        height:20px;
        left:-1rem;
        top:-0.07rem;
        z-index:999;
    }


    &.panel-dot::before {
        content:url('../img/verion/icons/icon-dot-red.png');
        transform: scale(.03);
        position:absolute;
        width:20px;
        height:20px;
        left:-0.8rem;
        top:0.15rem;
        z-index:999;
    }

    .icon {
        text-align:center;
        img {
            float:none;
            padding-top:0.5rem;
            width:141.001px;
            height:auto;
        }
    }
}
