.icon-group {
    width:100%;

    .item {
        width:auto;
        float:left;
        padding:0.1rem;

        .icon-round {
            border-radius: 50%;
        }

        img.icon {
            width:40px;
            height:auto;
        }
    }
}
