@media screen and (max-device-width: 769px) {
    html, body {
        font-size:18px;
    }

    .mobile-hidden                  { display:none; }
    .mobile-show                    { display:inline-block; }
    .mobile-w100                    { width:100% !important; }
    .mobile-pt1                     { padding-top:1rem !important; }
    .mobile-plr0                    { padding-left:0 !important; padding-right:0 !important; }
    .mobile-mt1                     { margin-top:1rem; }
    .mobile-mt2                     { margin-top:2rem; }
    .cell                           { width:100% !important; }
    .bg.bg-coding                   { background-position: center right -75vw; }
    .page .title                    { font-size:200%; }
    .page .center-horizontal        {text-align:center; }
    .page .center-horizontal .logo  { float:none; }
    .page .logo.logo-small          { height:40px; }
    .page .img.img-larger           { width:100%; }
    .text-smaller                   { font-size:80%; }
    .page .img.img-medium           { width:80% !important; }
    .panel.panel-check::before      { left:-1.4rem; top: -0.38rem; transform:scale(.04); }
    header .nav .item               { font-size:2.3vw; }
    .btn                            { padding:0.7rem 1.3rem; font-size:90%; }
    .bg.bg-arrows-down              { background-position: bottom right -35vw; }

}
