footer,
.footer {
    background:#333;
    font-size:60%;
    padding:0.5rem 0;

    .left-frame {
        width:200px;
        padding-right:0.3rem;
    }
    .right-frame {
        width:calc(100% - 200px);
        padding-left:0.3rem;
        font-size:90%;
    }

    .title {
        font-family:'roboto-light';
        text-transform:uppercase;
        font-size:70%;
        border-bottom:1px solid #01A17F; //#5BDBEA;
        margin-bottom:0.3rem;
    }

    .item {
        width:100% !important;
        float:left;
        font-size:90%;
        color:#f8f8f8;
        &:hover {
            color:#01A17F;
            cursor:pointer;
        }
    }

    .logo {
        img {
            height:50.001px;
            width:auto;
        }
    }
}
