html, body, div, span, img, input, select, textarea, footer, header, nav, main {
    position:relative;
    float:left;
    width:100%;
    height:auto;
    border:none;
    line-height:1.2;
    box-sizing: border-box;
    padding:0;
    margin:0;
    color:#333;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
