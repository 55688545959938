.btn {
    padding:0.4rem 1rem;
    border-radius:15px;
    border:2px solid #216B6C;
    background:#01A17F;
    font-size:80%;
    text-transform:uppercase;

    &.btn-scroll-top {
        position:fixed;
        bottom:0.5rem;
        right:0.5rem;
        width:30px;
        height:30px;
        padding:0;
        border:0 !important;
        border-radius:50%;
        z-index:99999;
        img {
            float:left;
            width:100%;
            height:auto;
        }
        &:hover {
            cursor:pointer;
        }
    }

    &.btn-default {
        font-size:70%;
        &:hover {
            background:#017258;
            cursor:pointer;
            border-color:transparent;
        }
    }


    &:hover {
        background-position:top center;
    }
}
