header {
    .logo {
        width:auto;
        height:60.0001px;
    }

    .nav {
        width:auto;
        float:right;
        padding-top:1rem;

        .item {
            width:auto;
            padding:0 0.2rem;
            font-size:50%;
            text-transform:uppercase;
            &:hover {
                color:#01A17F;
                cursor:pointer;
            }
        }
    }
}
