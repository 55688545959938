.bg {
    &.bg-verion {
        background-image:url('../img/verion/images/bg-verion.webp');
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
    }
    &.bg-verion-white {
        background-image:url('../img/verion/images/bg-verion-white.webp') !important;
        background-size: cover !important;
        background-position:center center !important;
        background-repeat: no-repeat !important;
    }

    &.bg-cubes {
        background-image:url('../img/verion/images/img-cubes-gold.webp');
        background-size: 32.001%;
        background-position:top 40% right 5rem ;
        background-repeat: no-repeat;
    }

    &.bg-gradient {
        background:#000;
        background-image:url('../img/verion/images/bg-gradient.png');
    }
    &.bg-structure {
        background:#000;
        background-image:url('../img/verion/images/bg-structure.png');
        background-size: cover;
        background-position:center center;
    }
    &.bg-coding {
        background:#000;
        background-image:url('../img/verion/images/bg-coding.webp');
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
    }
    &.bg-arrows-down {
        background:#000;
        background-image:url('../img/verion/images/bg-arrows-down.webp')  !important;
        background-size: cover;
        background-position:bottom right;
        background-repeat: no-repeat;
    }
}
