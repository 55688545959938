.legal {
    position:fixed;
    width:100%;
    height:auto;
    bottom:0;
    background: rgb(1,161,127);
    background: linear-gradient(242deg, rgba(1,161,127,0.97) 0%, rgba(1,161,127,0.98) 100%);
    border-top:3px solid #333;
    //background:#FFF3E8;
    padding:0.5rem 1rem;
    font-size:80%;
    color:#333;
    z-index:999999;

    .legal-module {
        width:100%;

        .title {
            font-family:'roboto-medium';
            text-transform: uppercase;
            padding-top:0.3rem;
            font-size:80%;
            color:#017258;
            border-bottom:1px solid #017258;
        }
        .content {
            width:100%;
            font-size:80%;

        }
    }



}
