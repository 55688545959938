.pages {
    min-height:calc(100% - 130px);
    height: calc(100% - 73px);
}
.page {
    .at-bottom {
        position:absolute;
        height:auto;
        width:calc(100% - 2rem);
        bottom:0;
    }

    .title {
        font-family:'roboto-medium';
        font-size:240%;
        padding-top:4rem;
    }
    .title2 {
        font-family:'roboto-medium';
        font-size:160%;
    }


    .img {
        &.img-medium {
            width:60%;
            height:auto;
        }
        &.img-larger {
            float:none;
            width:77.001%;
            height:auto;
        }
    }

    .logo {
        &.logo-small {
            height:60px;
            width:auto;
        }
    }



    &.page-light {
        background:#f4f4f4;
        color:#333 !important;
        .title,
        div {
            color:#333;
        }
    }

    &.page-grey {
        background:#b1b1b1;
        color:#333 !important;
    }

}
